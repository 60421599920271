const daysGroup = [
  { name: '1D', selected: true },
  { name: '7D', selected: false },
  { name: '1M', selected: false },
  { name: '3M', selected: false },
  { name: '1Y', selected: false },
  { name: 'all', selected: false }]

export default {
  daysGroup: daysGroup,
}
